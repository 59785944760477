import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

const PageCookiePolicy = ({ data, location }) => {
  const intl = useIntl();

  return(
  <Layout location={location}>
    <Seo
      title={intl.locale === "en" ? `Cookie Policy` : `Cookie Policy`}
      description={intl.locale === "en" ? `Cookie Policy` : `Cookie Policy`}
    />
    <div className="container container-document">
      <h1>Cookie Policy</h1>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/0346ff73-7031-4b95-b9b6-a143a8d31b88/cd.js"
        type="text/javascript" async>
      </script>
    </div>
  </Layout>
)}

export default PageCookiePolicy
